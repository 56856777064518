import React, { useState, useEffect } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';

const CustomDateInput = props => {
  const { date, setDate, id, placeholder } = props;
  const [focused, setFocused] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    import('react-dates/lib/css/_datepicker.css');
    import('./singleDatePicker.css');
  }, []);

  if (!mounted) {
    return <div className="custom-date-picker-placeholder" />;
  }

  return (
    <div className="custom-date-picker">
      <SingleDatePicker
        date={date}
        onDateChange={newDate => setDate(newDate)}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        id={id}
        placeholder={placeholder}
        displayFormat="DD.MM"
        numberOfMonths={1}
        readOnly
      />
    </div>
  );
};

export default CustomDateInput;
