import React, { useEffect, useState } from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import PopupOpenerButton from '../PopupOpenerButton/PopupOpenerButton';
import parameterIcon from './parameter.png';
import css from './SearchFiltersPrimary.module.css';
import { SearchBar } from '../../../components/Navbar/helpers/searchBar/search-bar';
import SearchIcon from '../../../components/Topbar/SearchIcon';
import FilterModal from './filterModal/filterModal';
import CustomDateInput from './singleDatePicker';

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
    history,
    urlQueryParams,
    clearFilter,
    totalItems,
    handleFlatFilter,
    reduceFilter,
    currentFilterState,
    guestsFilter,
    validQueryParams,
    onMapIconClick,
    isSearchMapOpenOnMobile,
    setCurrentBounds,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [currentFilterFocus, setCurrentFilterFocus] = useState(null);

  const classes = classNames(rootClassName || css.root, className);
  const { address, guests } = validQueryParams;
  const guestsFilters = guests ? guests.split(',')[1] : null;
  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <PopupOpenerButton
      isSelected={isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0}
      toggleOpen={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      />
    </PopupOpenerButton>
  ) : null;

  const svgPlaceholder = (placeholder, isDate) =>
    encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="20">
      <text x="6" y="13" fill="${
        !isDate ? '#a1a1a1' : '#FFFFF'
      }" font-family="poppins, sans-serif" font-size="7px">${placeholder}</text>
    </svg>
  `);

  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    if (isBrowser) {
      const handleResize = () => {
        setIsMobileDevice(window.innerWidth < 561);
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isBrowser]);

  const handleEndDateChange = date => {
    setToDate(date);
    setCurrentFilterFocus(null);
  };

  const startDateInput = (
    <CustomDateInput date={fromDate} setDate={setFromDate} id="from" placeholder={'01.01'} />
  );
  const endDateInput = (
    <CustomDateInput date={toDate} setDate={handleEndDateChange} id="to" placeholder={'31.12'} />
  );

  if (isSearchMapOpenOnMobile) {
    return <></>;
  } else if (currentFilterFocus == 'location') {
    return (
      <div className={classes}>
        <div className={css.mainFiltersWrapper}>
          <div className={css.searchFilterItemMobile}>
            <div className={css.label}>
              <span>
                <FormattedMessage id="criteria_header_6" />{' '}
              </span>
              <div className={css.closeIcon} onClick={() => setCurrentFilterFocus(null)}>
                x
              </div>
            </div>
            <SearchBar
              history={history}
              currentSearchParams={urlQueryParams}
              validQueryParams={validQueryParams}
              setCurrentBounds={setCurrentBounds}
            />
          </div>
        </div>{' '}
      </div>
    );
  } else if (currentFilterFocus == 'date') {
    return (
      <div className={classes}>
        <div className={css.mainFiltersWrapper}>
          <div className={css.datesDisplay}>
            <div className={css.label}>
              <span className={css.filterTitle}>
                <FormattedMessage id="SearchFiltersPrimary.datesLabel" />
              </span>
              {/*  <div className={css.closeIcon} onClick={() => setCurrentFilterFocus(null)}>
                x
              </div> */}
            </div>
            <div className={css.dateInputs}>
              {startDateInput}
              {endDateInput}
              <div className={css.searchFilterIconMobile} onClick={() => setCurrentFilterFocus(null)}>
                <div className={css.searchIconMobile}>
                  <SearchIcon rootClassName={css.searchIconMain} />
                </div>
              </div>
            </div>
          </div>
        </div>{' '}
      </div>
    );
  }

  return (
    <div className={classes}>
      <div className={css.mainFiltersWrapper}>
        {!currentFilterState ? (
          <div
            className={css.filterContainer}
            onClick={() => {
              isMobileDevice ? '' : handleFlatFilter();
            }}
          >
            <div className={css.filter}>
              <div
                className={css.filterItem}
                data-elb="section"
                data-elb-section={`name:addressFilter;address:${address};`}
                data-elbaction="visible"
                onClick={() => {
                  isMobileDevice ? setCurrentFilterFocus('location') : '';
                }}
              >
                {address ? (
                  address
                ) : (
                  <FormattedMessage id="SearchFiltersPrimary.placesPlaceholder" />
                )}
              </div>
              <div
                className={css.filterItem}
                data-elb="section"
                data-elb-section={`name:periodFilter;from-date:${fromDate};to-date:${toDate};`}
                data-elbaction="visible"
                onClick={() => {
                  isMobileDevice ? setCurrentFilterFocus('date') : '';
                }}
              >
                {fromDate || toDate ? (
                  `${fromDate ? fromDate.format('DD.MM') : ''} - ${
                    toDate ? toDate.format('DD.MM') : ''
                  }`
                ) : (
                  <FormattedMessage id="SearchFiltersPrimary.datesPlaceholder" />
                )}
              </div>
              <div
                className={css.filterItemGuests}
                data-elb="section"
                data-elb-section={`name:guestsFilter;guests:${guests};`}
                data-elbaction="visible"
              >
                {guestsFilters ? (
                  <>
                    {guestsFilters} <FormattedMessage id="SearchFiltersPrimary.guests" />{' '}
                  </>
                ) : (
                  <FormattedMessage id="SearchFiltersPrimary.guestsPlaceholder" />
                )}
              </div>
              <div className={css.searchFilterIcon}>
                <div className={css.searchIcon}>
                  <SearchIcon rootClassName={css.searchIconMain} />
                </div>
              </div>
            </div>
            <div className={css.filter}>
              <div className={css.divider}></div>
              <div className={css.mapIcon} onClick={onMapIconClick}>
                <FormattedMessage
                  id="SearchFiltersMobile.openMapView"
                  className={css.mapIconText}
                />
              </div>
              <div className={css.filterItem} onClick={() => setModalOpen(true)}>
                <img src={parameterIcon} className={css.paramIcon} />
              </div>
            </div>
          </div>
        ) : (
          <div className={css.filterPlain}>
            <div className={css.emptyclass} onMouseEnter={reduceFilter}></div>
            <div className={css.emptyclass2} onMouseEnter={reduceFilter}></div>
            <div className={css.searchFilterItem}>
              <span>
                <FormattedMessage id="criteria_header_6" />{' '}
              </span>
              <SearchBar
                history={history}
                currentSearchParams={urlQueryParams}
                validQueryParams={validQueryParams}
                setCurrentBounds={setCurrentBounds}
              />
            </div>
            <div>
              <span className={css.filterTitle}>
                <FormattedMessage id="SearchFiltersPrimary.datesLabel" />
              </span>
              <div className={css.dateInputs}>
                {startDateInput}
                {endDateInput}
              </div>
            </div>
            <div>{guestsFilter}</div>
            <div className={css.searchFilterItemPlain}>
              <div className={css.searchFilterPlainWrapper} onClick={reduceFilter}>
                <div className={css.searchIconPlain}>
                  <SearchIcon rootClassName={css.searchIconMain} />
                </div>
                <span>
                  {' '}
                  <FormattedMessage id="navbar.searchButtonText" />{' '}
                </span>
              </div>
            </div>
            <div className={css.filterButtonPlain}>
              <span className={css.results}>
                {' '}
                <FormattedMessage
                  id="MainPanelHeader.foundResults"
                  values={{ count: totalItems }}
                />{' '}
              </span>
              <div className={css.filterItemPlain} onClick={() => setModalOpen(true)}>
                <img src={parameterIcon} className={css.paramIcon} />
                <span> Filter</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <FilterModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        clearFilter={clearFilter}
        totalItems={totalItems}
      >
        {/*    */}
        <div className={css.filters}>
          <div className={css.guestsMobile}> {guestsFilter}</div>

          {children}
          {/*           {toggleSecondaryFiltersOpenButton} */}
        </div>
      </FilterModal>
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
